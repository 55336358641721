import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  firstName: null,
  lastName: null,
  subject: null,
  origin: null,
  email: null,
  message: null
});

export default () => ({
  ...initialCrudState(initialModel)
});
